import React, { useEffect, useState } from 'react';
import { Table, Spin, Alert, Button } from 'antd';
import {
	API_URL_ORDER_FORECAST,
	API_URL_ORDER_FORECAST_EXPORT,
	get,
} from '../../App/Api';
import { PerpageForecast } from './PerPageForecast';
import { AccordeonFiltersForecast } from './AccordeonForecast/AccordeonFiltersForecast';
import { useTranslation } from 'react-i18next';
import MainLayout from '../../Layout/MainLayout';

export function Forecast({ userDetail }) {
	const { t, i18n } = useTranslation();

	const [forecast, setForecast] = useState([]);
	const [forecastPerPage, setForecastPerPage] = useState(10);
	const [forecastPage, setForecastPage] = useState(1);

	// const [clientIds, setClientIds] = useState([]);
	const [postalCodes, setPostalCodes] = useState('');
	const [cities, setCities] = useState('');
	const [states, setStates] = useState('');

	const [endDateReceived, setEndDateReceived] = useState('');
	const [startDateReceived, setStartDateReceived] = useState('');

	const [hasMorePages, setHasMorePages] = useState(true);
	const [isLoadingMore, setIsLoadingMore] = useState(false);

	const [loading, setLoading] = useState(false);

	const [error, setError] = useState(null);

	useEffect(() => {
		fetchData();
	}, [forecastPerPage, forecastPage, i18n.language]);

	const fetchData = async () => {
		setLoading(true);

		try {
			const response = await get(API_URL_ORDER_FORECAST, {
				perPage: forecastPerPage,
				page: forecastPage,
				startDateReceived: startDateReceived ? startDateReceived : undefined,
				endDateReceived: endDateReceived ? endDateReceived : undefined,
				states: states ? states : undefined,
				cities: cities ? cities : undefined,
				postalCodes: postalCodes ? postalCodes : undefined,
			});

			if (Array.isArray(response.data)) {
				setHasMorePages(response.data.length === forecastPerPage);
				setForecast(response.data);
			} else {
				throw new Error('Data format is incorrect');
			}
		} catch (error) {
			setError(error.message);
		} finally {
			setLoading(false);
			setIsLoadingMore(false);
		}
	};

	const loadMoreForecast = () => {
		if (hasMorePages && !isLoadingMore) {
			setIsLoadingMore(true);
			setForecastPage((prevPage) => prevPage + 1);
		}
	};

	const handlePreviousPage = () => {
		if (forecastPage > 1) {
			setForecastPage((prevPage) => prevPage - 1);
		}
	};

	const handleSearch = () => {
		setForecastPage(1);
		fetchData();
	};

	const handleExport = async () => {
		const response = await get(
			API_URL_ORDER_FORECAST_EXPORT,
			{
				perPage: forecastPerPage,
				page: forecastPage,
				startDateReceived: startDateReceived ? startDateReceived : undefined,
				endDateReceived: endDateReceived ? endDateReceived : undefined,
				states: states ? states : undefined,
				cities: cities ? cities : undefined,
				postalCodes: postalCodes ? postalCodes : undefined,
			},
			{
				responseType: 'blob',
			}
		);

		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `Forecast_${Date.now()}.xlsx`);
		document.body.appendChild(link);
		link.click();
		link.remove();
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			handleSearch();
		}
	};

	const columns = [
		{
			title: t('postalCode'),
			dataIndex: 'postalCode',
			key: 'postalCode',
			align: 'center',
		},
		{
			title: t('city'),
			dataIndex: 'city',
			key: 'city',
			align: 'center',
		},
		{
			title: t('state'),
			dataIndex: 'state',
			key: 'state',
			align: 'center',
		},
		{
			title: t('qty'),
			dataIndex: 'qty',
			key: 'qty',
			align: 'center',
			render: (text) => text || '0',
		},
	];

	const filterProps = {
		// FILTERS
		setStates,
		setCities,
		setPostalCodes,
		// setClientIds,
		setEndDateReceived,
		setStartDateReceived,

		handleSearch,
		handleExport,
	};

	return (
		<MainLayout>
			<div className="filters">
				<form onKeyDown={handleKeyPress}>
					<AccordeonFiltersForecast filterProps={filterProps} />
				</form>
			</div>
			{loading ? (
				<Spin size="large" />
			) : error ? (
				<Alert message="Error" description={error} type="error" showIcon />
			) : (
				<>
					<div className="employee-footer">
						<Button onClick={handlePreviousPage} disabled={forecastPage === 1}>
							{t('previousPage')}
						</Button>
						<Button
							onClick={loadMoreForecast}
							disabled={!hasMorePages || isLoadingMore}
						>
							{t('nextPage')}
						</Button>
						<Button type="primary" onClick={filterProps.handleExport}>
							{t('export')}
						</Button>
						<PerpageForecast
							forecastPerPage={forecastPerPage}
							setForecastPerPage={setForecastPerPage}
						/>
					</div>
					<div className="forecast-table">
						<Table
							dataSource={forecast}
							columns={columns}
							rowKey="fleetId"
							pagination={false}
						/>
					</div>
				</>
			)}
		</MainLayout>
	);
}
