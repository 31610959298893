import React, { useEffect, useState } from 'react';
import { Button, Table, Card, Space, Statistic, Row, Col } from 'antd';

import {
	API_URL_ROUTES_EMPLOYEE_ID_ORDERS_ASSIGNED,
	API_URL_ROUTES_EMPLOYEE_ID_ORDERS_ASSIGNED_EXPORT,
	get,
} from '../../../App/Api';
import { useTranslation } from 'react-i18next';
import { Paginator } from '../../../Paginator';

export function RoutesDetails({ closeRoutesDetails, selectedEmployee, date }) {
	const { t, i18n } = useTranslation();
	const [perPage, setPerPage] = useState(10);
	const [page, setPage] = useState(1);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [hasMorePages, setHasMorePages] = useState(true);
	const [isLoadingMore, setIsLoadingMore] = useState(false);
	const [details, setDetails] = useState(null);
	useEffect(() => {
		fetchData();
	}, [perPage, page, i18n.language]);

	useEffect(() => {}, [details]);
	const fetchData = async () => {
		setLoading(true);

		try {
			let url = API_URL_ROUTES_EMPLOYEE_ID_ORDERS_ASSIGNED;
			const response = await get(
				url.replace('{{employeeId}}', selectedEmployee.employeeId),
				{
					fleetIds: [selectedEmployee.fleetId],
					date,
					perPage,
					page,
				}
			);

			if (Array.isArray(response.data)) {
				setHasMorePages(response.data.length === perPage);
				setDetails(response.data);
				setError(null);
			} else {
				throw new Error('Data format is incorrect');
			}
		} catch (error) {
			setError(error?.message);
		} finally {
			setLoading(false);
			setIsLoadingMore(false);
		}
	};

	const columns = [
		{
			title: '#',
			dataIndex: 'position',
			key: 'position',
			align: 'center',
			render: (text, record, index) => perPage * (page - 1) + index + 1,
		},
		{
			title: t('orders.reference'),
			dataIndex: 'reference',
			key: 'reference',
			align: 'center',
		},
		{
			title: t('orders.postalCode'),
			dataIndex: 'postalCode',
			key: 'postalCode',
			align: 'center',
		},
		{
			title: t('orders.currentAddress'),
			dataIndex: 'currentAddress',
			key: 'currentAddress',
			align: 'center',
		},
		{
			title: t('orderStatus.RFD.name'),
			dataIndex: 'RFD',
			key: 'RFD',
			align: 'center',
		},
		{
			title: t('orderStatus.FLD.name'),
			dataIndex: 'FLD',
			key: 'FLD',
			align: 'center',
		},
		{
			title: t('orderStatus.DLV.name'),
			dataIndex: 'DLV',
			key: 'DLV',
			align: 'center',
		},
	];

	const loadMoreRoutes = () => {
		if (hasMorePages && !isLoadingMore) {
			setIsLoadingMore(true);
			setPage((prevPage) => prevPage + 1);
		}
	};

	const handlePreviousPage = () => {
		if (page > 1) {
			setPage((prevPage) => prevPage - 1);
		}
	};

	const handleSearch = () => {
		setPage(1);
		fetchData();
	};

	const handleExport = async () => {
		const response = await get(
			API_URL_ROUTES_EMPLOYEE_ID_ORDERS_ASSIGNED_EXPORT.replace(
				'{{employeeId}}',
				selectedEmployee.employeeId
			),
			{
				fleetIds: [selectedEmployee.fleetId],
				date,
			},
			{
				responseType: 'blob',
			}
		);

		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `Forecast_${Date.now()}.xlsx`);
		document.body.appendChild(link);
		link.click();
		link.remove();
	};

	return (
		<Space direction="vertical" size="middle" style={{ display: 'flex' }}>
			<Card title={selectedEmployee?.employeeName}>
				<Card>
					<Row>
						<Col span={6}>
							<Statistic
								title={t('orders.qty')}
								value={selectedEmployee.orders}
								precision={0}
							/>
						</Col>
						<Col span={6}>
							<Statistic
								title={t('orderStatus.RFD.name')}
								value={selectedEmployee.RFD}
								precision={0}
							/>
						</Col>
						<Col span={6}>
							<Statistic
								title={t('orderStatus.FLD.name')}
								value={selectedEmployee.FLD}
								precision={0}
							/>
						</Col>
						<Col span={6}>
							<Statistic
								title={t('orderStatus.DLV.name')}
								value={selectedEmployee.DLV}
								precision={0}
							/>
						</Col>
					</Row>
					<span>
						{t('orders.postalCode')}: {selectedEmployee.postalCodes}
					</span>
				</Card>
				<Card>
					<Button type="primary" onClick={handleExport}>
						{t('export')}
					</Button>
					<Button type="primary" onClick={closeRoutesDetails}>
						{t('back')}
					</Button>
					<Button onClick={handlePreviousPage} disabled={page === 1}>
						{t('previousPage')}
					</Button>
					<Button
						onClick={loadMoreRoutes}
						disabled={!hasMorePages || isLoadingMore}
					>
						{t('nextPage')}
					</Button>
					<Paginator perPage={perPage} setPerPage={setPerPage} />
				</Card>
				<Table
					dataSource={details}
					columns={columns}
					rowKey="employeeOrder"
					pagination={false}
				/>
			</Card>
		</Space>
	);
}
