import React, { useEffect, useState } from 'react';
import { Table, Spin, Alert, Button, Space } from 'antd';
import { API_URL_ROUTES, get } from '../../App/Api';
import { PerpageRoutes } from './PerPageRoutes';
import { AccordeonFiltersRoutes } from './AccordeonRoutes/AccordeonFilterRoutes';
import { useTranslation } from 'react-i18next';
import { saveToLocalStorage } from '../Login/storage';
import { RoutesDetails } from './Details';
import MainLayout from '../../Layout/MainLayout';

export function RoutesFollowing() {
	const { t, i18n } = useTranslation();
	const [routes, setRoutes] = useState([]);
	const [routesPerPage, setRoutesPerPage] = useState(10);
	const [routesPage, setRoutesPage] = useState(1);
	const [employeeIds, setEmployeeIds] = useState([]);
	const [date, setDate] = useState(null);
	const [fleetIds, setFleetIds] = useState([]);
	const [fleetCities, setFleetCities] = useState([]);
	const [fleetStates, setFleetStates] = useState([]);
	const [searchReference, setSearchReference] = useState(null);
	const [clientIds, setClientIds] = useState([]);
	const [hasMorePages, setHasMorePages] = useState(true);
	const [isLoadingMore, setIsLoadingMore] = useState(false);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [selectedEmployee, setSelectedEmployee] = useState(null);

	const openRoutesDetails = (employee) => {
		setSelectedEmployee(employee);
	};

	const closeRoutesDetails = () => {
		setSelectedEmployee(null);
	};

	useEffect(() => {
		fetchData();
	}, [routesPerPage, routesPage, i18n.language]);

	const fetchData = async () => {
		setLoading(true);

		try {
			let url = API_URL_ROUTES;

			const response = await get(url, {
				perPage: routesPerPage,
				page: routesPage,
				date,
				fleetIds,
				fleetCities,
				fleetStates,
				references: searchReference,
				employeeIds,
				clientIds,
			});

			if (Array.isArray(response.data)) {
				setHasMorePages(response.data.length === routesPerPage);
				setRoutes(response.data);
				setError(null);
			} else {
				throw new Error('Data format is incorrect');
			}
		} catch (error) {
			setError(error?.message);
		} finally {
			setLoading(false);
			setIsLoadingMore(false);
		}
	};

	const loadMoreRoutes = () => {
		if (hasMorePages && !isLoadingMore) {
			setIsLoadingMore(true);
			setRoutesPage((prevPage) => prevPage + 1);
		}
	};

	const handlePreviousPage = () => {
		if (routesPage > 1) {
			setRoutesPage((prevPage) => prevPage - 1);
		}
	};

	const handleSearch = () => {
		setRoutesPage(1);
		fetchData();
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			handleSearch();
		}
	};
	const handleInputOrderSearch = (value) => {
		if (!value) {
			value = null;
		}
		setSearchReference(value);
		saveToLocalStorage('routes.searchReference', value);
	};

	const columns = [
		{
			title: t('fleets.name'),
			dataIndex: 'fleetName',
			key: 'fleetName',
			align: 'center',
		},
		{
			title: t('employees.name'),
			dataIndex: 'employeeName',
			key: 'employeeName',
			align: 'center',
		},
		{
			title: t('orders.qty'),
			dataIndex: 'orders',
			key: 'orders',
			align: 'center',
		},
		{
			title: t('orderStatus.RFD.name'),
			dataIndex: 'RFD',
			key: 'RFD',
			align: 'center',
			render: (text) => text || '0',
		},
		{
			title: t('orderStatus.FLD.name'),
			dataIndex: 'FLD',
			key: 'FLD',
			align: 'center',
			render: (text) => text || '0',
		},
		{
			title: t('orderStatus.DLV.name'),
			dataIndex: 'DLV',
			key: 'DLV',
			align: 'center',
			render: (text) => text || '0',
		},
		{
			title: t('actions'),
			key: 'actions',
			render: (employee) => (
				<Space>
					<Button onClick={() => openRoutesDetails(employee)}>
						{t('routes.detail')}
					</Button>
				</Space>
			),
		},
	];

	const filterProps = {
		setDate,
		setFleetIds,
		setFleetCities,
		setFleetStates,
		setEmployeeIds,
		setClientIds,
		handleSearch,
		handleInputOrderSearch,
	};

	return (
		<MainLayout>
			<div
				className="filters"
				style={{ display: !selectedEmployee ? 'block' : 'none' }}
			>
				<form onKeyDown={handleKeyPress}>
					<AccordeonFiltersRoutes filterProps={filterProps} />
				</form>
			</div>
			{!selectedEmployee && (
				<>
					{loading ? (
						<Spin size="large" />
					) : error ? (
						<Alert message="Error" description={error} type="error" showIcon />
					) : (
						<>
							<div className="employee-footer">
								<Button
									onClick={handlePreviousPage}
									disabled={routesPage === 1}
								>
									{t('previousPage')}
								</Button>
								<Button
									onClick={loadMoreRoutes}
									disabled={!hasMorePages || isLoadingMore}
								>
									{t('nextPage')}
								</Button>
								<PerpageRoutes
									routesPerPage={routesPerPage}
									setRoutesPerPage={setRoutesPerPage}
								/>
							</div>
							<div className="routes-table">
								<Table
									dataSource={routes}
									columns={columns}
									rowKey="fleetId"
									pagination={false}
								/>
							</div>
						</>
					)}
				</>
			)}

			{selectedEmployee && (
				<RoutesDetails
					closeRoutesDetails={closeRoutesDetails}
					selectedEmployee={selectedEmployee}
					date={date}
				/>
			)}
		</MainLayout>
	);
}
