import React, { useState } from 'react';
import imgLogo from './img/logo3.jpeg';
import menu from './img/world.svg';
import close from './img/close.svg';
import imgLogo1ro from './img/logo1ro.png';
import imgLocation from './img/location.svg';
import './main.css';
import { LenguajeService } from '../Lenguajes';
import { LenguajeSelect } from '../navAcordion';

export function Navbar() {
	const [showModal, setShowModal] = useState(false);

	function openShowModal() {
		setShowModal(true);
	}

	function closeShowModal() {
		setShowModal(false);
	}

	return (
		<>
			<header>
				{showModal && (
					<img
						src={close}
						className="close"
						alt="close"
						onClick={closeShowModal}
					/>
				)}
				{showModal && <LenguajeService />}

				<nav className="navbar mobile">
					<figure className="figure__navbar">
						<img src={imgLogo1ro} alt="logo" />
					</figure>

					<div className="container__navbar">
						<img
							src={menu}
							alt="hamburguer"
							className="hamburguer"
							onClick={openShowModal}
						/>
					</div>
				</nav>

				<nav className="desktop">
					<div className="desktop__container">
						<div className="desktop__texts">
							<img src={imgLocation} alt="" />
							<h1>Movers</h1>
						</div>

						<div className="container__navbar">
							<span>
								<LenguajeSelect />
							</span>
							<img src={imgLogo1ro} alt="logo" className="logo" />
						</div>
					</div>
				</nav>
			</header>
		</>
	);
}
