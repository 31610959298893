import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { API_URL_FLEETS_SELECT, get } from '../../App/Api';
import { useTranslation } from 'react-i18next';

export function FleetFilter({ mode = 'multiple', setFleetIds, fleetIds = [] }) {
	const { t, i18n } = useTranslation();

	const [options, setOptions] = useState({});

	useEffect(() => {
		fetchFleets();
	}, [i18n.language]);

	const fetchFleets = async () => {
		try {
			const response = await get(API_URL_FLEETS_SELECT);

			let newOptions = [];

			if (response?.data?.length) {
				newOptions = response.data.map((fleet) => {
					return {
						value: fleet.fleetId,
						label: fleet.name,
					};
				});
			}
			setOptions(newOptions);
		} catch (error) {
			console.error('Error fetching clients:', error);
		}
	};

	const handleOptionsChange = (value) => {
		setFleetIds(value);
	};

	return (
		<Select
			showSearch
			mode={mode}
			optionFilterProp="label"
			onChange={handleOptionsChange}
			defaultValue={fleetIds}
			style={{ width: '100%', margin: '10px 0' }}
			placeholder={t('filters.fleets')}
			options={options}
		></Select>
	);
}
