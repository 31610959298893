import React from 'react';
import { Input, Space } from 'antd';

export const InputOrderSearch = ({ setSearchInput, searchInput = '' }) => {
	const handleInputOrderSearch = (value) => {
		setSearchInput(value);
	};

	return (
		<Space
			direction="vertical"
			style={{ width: '100%', margin: '10px 0' }}
			size="large"
		>
			<Input
				placeholder="Search by reference"
				allowClear
				onChange={(e) => handleInputOrderSearch(e.target.value)}
				value={searchInput}
			/>
		</Space>
	);
};
