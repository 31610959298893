import { Select, TreeSelect } from 'antd';
import { Option } from 'antd/es/mentions';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_URL_ORDER_STATUS_SELECT_TREE, get } from '../../../App/Api';

export function OrderStatusCodeFilter({
	selectedStatusCodes,
	setSelectedStatusCodes,
}) {
	const { t, i18n } = useTranslation();

	const [orderStatus, setOrderStatus] = useState([]);

	useEffect(() => {
		fetchOrderStatus();
	}, [i18n.language]);

	const fetchOrderStatus = async () => {
		try {
			const response = await get(API_URL_ORDER_STATUS_SELECT_TREE);
			setOrderStatus(response.data);
		} catch (error) {
			console.error('Error fetching clients:', error);
		}
	};

	const onChange = (newValue) => {
		setSelectedStatusCodes(newValue);
	};

	return (
		<TreeSelect
			showSearch
			style={{ width: '100%' }}
			value={selectedStatusCodes}
			dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
			placeholder="Please select"
			allowClear
			multiple
			treeDefaultExpandAll
			onChange={onChange}
			treeData={orderStatus}
			// onPopupScroll={onPopupScroll}
		/>
	);
}
