import React, { useEffect, useState } from 'react';
import afirmation from './img/descarga.svg';
import { ProgressBar } from '../ProgressBar';
import { useParams } from 'react-router-dom';
import { DeliveryTimeline } from '../../deliveryEvents';
import { useNavigate } from 'react-router-dom';
import './main.css';
import { API_URL_ORDER_TRACKING_REFERENCE, get } from '../../App/Api';
import { useTranslation } from 'react-i18next';
import { ReferenceHistory } from './ReferenceHistory';
import imgLogo1ro from './img/logo1ro.png';
import imgLocation from './img/location.svg';
import world from '../../Navbar/img/world.svg';
import close from '../../Navbar/img/close.svg';
import history from './img/manage_search.svg';
import historyClose from './img/manage_search_copy.svg';
import arrow from './img/chevron_left.svg';
import { LenguajeSelect } from '../../navAcordion';
import { LenguajeService } from '../../Lenguajes';
import { ReferenceHistoryMobile } from './ReferenceHistoryMobile';
import ZendeskWidget from '../../ZendeskWidget';
import { Card, Col, Row } from 'antd';
import Title from 'antd/es/typography/Title';

export function Detalles() {
	const { t, i18n } = useTranslation();
	const { reference } = useParams();
	const [order, setOrder] = useState(null);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const [selectedReference, setSelectedReference] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [showModalHistory, setShowModalHistory] = useState(false);
	useEffect(() => {
		const fetchOrder = async () => {
			const refs = reference.split('&');

			try {
				const responses = await Promise.all(
					refs.map(async (ref) => {
						try {
							const response = await get(
								API_URL_ORDER_TRACKING_REFERENCE.replace('{{reference}}', ref)
							);
							return { reference: ref, order: response.data };
						} catch (error) {
							return { reference: ref, order: null };
						}
					})
				);

				const validResponses = responses.filter((res) => res.order !== null);
				const orders = validResponses.map((res) => ({
					reference: res.reference,
					order: res.order,
				}));

				if (!orders.length) {
					navigate('/tracking');
				} else {
					setOrder(orders);
					setSelectedReference(orders[0]?.reference);
				}
			} catch (error) {
				return false;
			} finally {
				setLoading(false);
			}
		};

		fetchOrder();
	}, [reference, i18n.language, loading, navigate]);

	const handleSelectReference = (reference) => {
		setSelectedReference(reference);
	};

	function openShowModal() {
		setShowModal(true);
	}

	function closeShowModal() {
		setShowModal(false);
	}

	function openShowModalHistory() {
		setShowModalHistory(true);
	}

	function closeShowModalHistory() {
		setShowModalHistory(false);
	}

	useEffect(() => {
		if (!loading && !order) {
			navigate('/');
		}
	}, [loading, order, navigate]);

	if (loading) {
		<p>cargando........</p>;
	}

	if (!order) {
		return null;
	}

	const selectedOrder = order.find(
		(order) => order.reference === selectedReference
	);

	return (
		<>
			<section className="detalles">
				{showModal && (
					<img
						src={close}
						className="close"
						alt="close"
						onClick={closeShowModal}
					/>
				)}
				{showModal && <LenguajeService />}

				<div className="detalles__mobile mobile">
					<div className="detalles__texts-1">
						<img src={arrow} alt="arrow" onClick={() => navigate(-1)} />
					</div>

					<div className="detalles__texts-2">
						<img src={imgLocation} alt="" />
						<h1>Movers</h1>
					</div>

					<div className="detalles__texts-3">
						<img src={world} alt="hamburguer" onClick={openShowModal} />
						<img
							src={history}
							alt="hamburguer"
							onClick={openShowModalHistory}
						/>
					</div>
				</div>
				<div className="detalles__nav detalles__nav-desktop">
					<div className="detalles__nav-container">
						<div className="detalles__texts">
							<img src={imgLocation} alt="" />
							<h1>Movers</h1>
						</div>

						<div className="detalles__navbar">
							<button onClick={() => navigate('/tracking')}>
								{t('details.go_back')}
							</button>
							<LenguajeSelect />
							<img src={imgLogo1ro} alt="logo" className="detalles__logo" />
						</div>
					</div>
				</div>
				<div className="detalles__content-container">
					{order.length > 1 && (
						<div className="detalles__content">
							<h1>{t('details.previous_tracking_references')}</h1>
							<ReferenceHistory
								references={order}
								selectedReference={selectedReference}
								onSelectReference={handleSelectReference}
							/>
						</div>
					)}
					<Row
						justify="center"
						align="middle"
						style={{ height: '50vh', width: '100vw' }}
					>
						<Col>
							<Card>
								<Card.Grid
									style={{
										width: '30%',
										textAlign: 'center',
									}}
								>
									<ProgressBar
										order={selectedOrder.order}
										timeline={selectedOrder.order.timeline}
									/>
								</Card.Grid>
								<Card.Grid
									style={{
										width: '70%',
										textAlign: 'center',
									}}
								>
									<Title level={3}>{selectedOrder.order.currentStatus}</Title>
									<Title level={4}>{selectedOrder.reference}</Title>
									<Title level={4}>
										{selectedOrder.order.origin} ➡
										{selectedOrder.order.destination}
									</Title>
								</Card.Grid>
							</Card>
							<Card>
								<DeliveryTimeline
									detailStatus={selectedOrder.order.detailStatus}
								/>
							</Card>
						</Col>
					</Row>
				</div>
				<ZendeskWidget />
			</section>
		</>
	);
}
