import React from 'react';
import { Button, Col, Collapse, Input, Row } from 'antd';
import { ClientsFilterForecast } from '../../ClientsFilterForecast';
import { useTranslation } from 'react-i18next';
import { CitiesFilterForecast } from '../../CitiesFilterForecast';
import { StatesFilterForecast } from '../../StatesFilterForecast';
import { PostalCodesFilterForecast } from '../../PostalCodesFilterForecast';
import { ReceivedDateFilterForecast } from '../../ReceivedDateFilterForecast';
const { Panel } = Collapse;

export function AccordeonFiltersForecast({ filterProps }) {
	const { t } = useTranslation();

	return (
		<Collapse
			defaultActiveKey={[]}
			style={{
				backgroundColor: '#fff',
				borderRadius: '8px',
				marginBottom: '1rem',
				width: '100%',
				margin: '0 auto',
			}}
		>
			<Panel header={t('filters.filters')} key="1">
				<Row gutter={[16, 16]}>
					{/* <Col span={8}>
						<p style={{ fontSize: '13px', margin: '5px 0' }}>
							{t('clients')}
						</p>
						<ClientsFilterForecast {...filterProps} />
					</Col> */}
					<Col span={8}>
						<p style={{ fontSize: '13px', margin: '5px 0' }}>
							{t('filters.states')}
						</p>
						<StatesFilterForecast {...filterProps} />
					</Col>
					<Col span={8}>
						<p style={{ fontSize: '13px', margin: '5px 0' }}>
							{t('filters.cities')}
						</p>
						<CitiesFilterForecast {...filterProps} />
					</Col>
					<Col span={8}>
						<p style={{ fontSize: '13px', margin: '5px 0' }}>
							{t('filters.postalCodes')}
						</p>
						<PostalCodesFilterForecast {...filterProps} />
					</Col>
					<Col span={8}>
						<p style={{ fontSize: '13px', margin: '5px 0' }}>
							{t('filters.receivedDate')}
						</p>
						<ReceivedDateFilterForecast {...filterProps} />
					</Col>
					{/* <Col span={8}>
						<p style={{ fontSize: '13px', margin: '5px 0' }}>
							{t('filters.name')}
						</p>
						<EmployeeFilterFleets {...filterProps} />
					</Col>
					<Col span={8}>
						<p style={{ fontSize: '13px', margin: '5px 0' }}>
							{t('filters.city')}
						</p>
					</Col> */}
				</Row>
				<Row justify="end" align="middle" style={{ marginTop: '20px' }}>
					<Col>
						<Button type="primary" onClick={filterProps.handleSearch}>
							{t('filters.search')}
						</Button>
					</Col>
				</Row>
			</Panel>
		</Collapse>
	);
}
