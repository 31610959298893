import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

export function Paginator({ perPage, setPerPage }) {
	const { t } = useTranslation();

	const handlePerPageChange = (value) => {
		setPerPage(value);
	};

	return (
		<Select
			onChange={handlePerPageChange}
			value={perPage}
			style={{ width: '25%', margin: '10px 0' }}
		>
			<Select.Option value={10}>10</Select.Option>
			<Select.Option value={20}>20</Select.Option>
			<Select.Option value={50}>50</Select.Option>
			<Select.Option value={100}>100</Select.Option>
			<Select.Option value={200}>200</Select.Option>
		</Select>
	);
}
