import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

export function PerpageFleets({ fleetsPerPage, setFleetsPerPage }) {
	const { t } = useTranslation();

	const handlePerPageChange = (value) => {
		setFleetsPerPage(value);
	};

	return (
		<Select
			onChange={handlePerPageChange}
			value={fleetsPerPage}
			style={{ width: '25%', margin: '10px 0' }}
		>
			<Select.Option value={10}>10</Select.Option>
			<Select.Option value={20}>20</Select.Option>
			<Select.Option value={50}>50</Select.Option>
		</Select>
	);
}
