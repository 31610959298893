import React, { useEffect, useState } from 'react';
import { Button, Table, Spin, Checkbox, Flex } from 'antd';
import {
	API_URL_ORDERS,
	API_URL_ORDER_ID,
	API_URL_PODS_PDF,
	get,
} from '../../App/Api';
import { OrderDetails } from './ModalDetails';
import { AccordionFilters } from './Accordion/AccordionFilters';
import { useTranslation } from 'react-i18next';
import {
	getFromLocalStorage,
	removeFromLocalStorage,
	saveToLocalStorage,
} from '../Login/storage';
import {
	FilePdfOutlined,
	DeleteOutlined,
	ExpandAltOutlined,
} from '@ant-design/icons';
import { Paginator } from '../../Paginator';
import MainLayout from '../../Layout/MainLayout';
import { Link } from 'react-router-dom';

export function Orders() {
	const { t, i18n } = useTranslation();

	const [checkedOrders, setCheckedOrders] = useState(
		getFromLocalStorage('order.pdfs') || []
	);

	const [orders, setOrders] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [page, setPage] = useState(
		parseInt(getFromLocalStorage('currentPage'), 10) || 1
	);

	// STATE FOR PETITION
	const [searchInput, setSearchInput] = useState(
		getFromLocalStorage('order.searchInput')
	);
	const [businessIds, setBusinessIds] = useState(
		getFromLocalStorage('order.businessIds')
	);
	const [clientIds, setClientIds] = useState(
		getFromLocalStorage('order.clientIds')
	);
	const [employeeIds, setEmployeeIds] = useState(
		getFromLocalStorage('order.employeeIds')
	);
	const [fleetIds, setFleetIds] = useState(
		getFromLocalStorage('order.fleetIds')
	);
	const [selectedStatusCodes, setSelectedStatusCodes] = useState(
		getFromLocalStorage('order.selectedStatusCodes')
	);
	const [perPage, setPerPage] = useState(
		getFromLocalStorage('order.perPage') || 10
	);

	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [ordersDate, setOrdersDate] = useState([]);
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [details, setDetails] = useState(null);
	const [hideOrderFilters, setHideOrderFilters] = useState(
		getFromLocalStorage('order.hideOrderFilters')
	);
	const [hasMorePages, setHasMorePages] = useState(true);
	const [isLoadingMore, setIsLoadingMore] = useState(false);

	const [columns, setColumns] = useState([]);

	const fetchData = async () => {
		setLoading(true);
		try {
			let url = `${API_URL_ORDERS}`;
			const response = await get(url, {
				perPage,
				page,
				references: searchInput ? searchInput : null,
				businessIds,
				clientIds,
				employeeIds,
				fleetIds,
				orderStatusCodes: selectedStatusCodes,
			});
			setOrders(response.data);
			setHasMorePages(response.data.length === perPage);
		} catch (error) {
			setError(error.message);
		} finally {
			setLoading(false);
			setIsLoadingMore(false);
		}
	};

	const fetchDetails = async () => {
		if (!selectedOrder) {
			return;
		}

		try {
			const response = await get(
				API_URL_ORDER_ID.replace('{{orderId}}', selectedOrder)
			);
			setDetails(response.data);
		} catch (error) {
			console.error(t('orders.Error'), error);
		}
	};

	const checkPdfs = (e) => {
		const orderId = e.target?.orderId;
		const checked = e.target?.checked;
		const position = checkedOrders.indexOf(orderId);

		let newCheckedOrders = [...checkedOrders];
		if (checked && position === -1) {
			newCheckedOrders.push(orderId);
		} else if (!checked && position >= 0) {
			newCheckedOrders.splice(position, 1);
		}
		e.target.checked = checked;
		saveToLocalStorage('order.pdfs', newCheckedOrders);
		setCheckedOrders(newCheckedOrders);
	};

	const handleSearch = () => {
		setPage(1);
		fetchData();
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			handleSearch();
		}
	};

	const handlePreviousPage = () => {
		let newPage = page;
		if (page > 1) {
			newPage--;
			setIsLoadingMore(true);
			setPage(newPage);
		}
	};

	const loadMoreOrders = () => {
		const newPage = page + 1;
		setIsLoadingMore(true);
		setPage(newPage);
	};

	const handleGoToStart = () => {
		setPage(1);
	};

	const openDetails = (order) => {
		setSelectedOrder(order.orderId);
		setHideOrderFilters(true);
		saveToLocalStorage('order.hideOrderFilters', true);
		saveToLocalStorage('selectedOrder', order.orderId);
		saveToLocalStorage('selectedDetailsOrder', order.orderId);
	};

	const closeDetails = () => {
		setSelectedOrder(null);
		setDetails(null);
		removeFromLocalStorage('order.hideOrderFilters');
		setHideOrderFilters(false);
		removeFromLocalStorage('selectedOrder');
	};

	const cleanOrderPdfs = () => {
		saveToLocalStorage('order.pdfs', []);
		setCheckedOrders([]);
	};

	const savePageToLocalStorage = () => {
		saveToLocalStorage('currentPage', page.toString());
	};

	useEffect(() => {
		fetchDetails();
	}, [selectedOrder, checkedOrders]);

	useEffect(() => {
		fetchData();
	}, [page, perPage]);

	useEffect(() => {
		savePageToLocalStorage(page);
	}, [page]);

	useEffect(() => {
		setColumns([
			{
				title: t('orders.pdf'),
				key: 'pdfs',
				render: (order, record) => (
					<>
						{order.orderStatusParentCode === 'DLV' && (
							<Checkbox
								key={'checkbox' + order.orderId}
								value={order.orderId}
								orderId={order.orderId}
								checked={!!checkedOrders?.includes(order.orderId)}
								onChange={checkPdfs}
							/>
						)}
					</>
				),
			},
			{
				title: t('orders.Reference'),
				dataIndex: 'reference',
				key: 'reference',
			},
			{
				title: t('fleets.name'),
				dataIndex: 'fleetName',
				key: 'fleetName',
			},
			{
				title: t('employees.name'),
				dataIndex: 'employeeName',
				key: 'employeeName',
			},
			{
				title: t('orders.status'),
				dataIndex: 'status',
				key: 'status',
				align: 'center',
				render: (text, record) => (
					<>
						{record?.orderStatus === record?.orderStatusParent ? (
							<>{record?.orderStatusParent}</>
						) : (
							<>
								{record?.orderStatusParent}-{record?.orderStatus}
							</>
						)}
					</>
				),
			},
			{
				title: t('orders.currentAddress'),
				dataIndex: 'currentAddress',
				key: 'currentAddress',
			},
			{
				title: t('orders.postalCode'),
				dataIndex: 'postalCode',
				key: 'postalCode',
			},
			{
				title: t('orders.city'),
				dataIndex: 'cityName',
				key: 'cityName',
			},
			{
				title: t('orders.Actions'),
				key: 'actions',
				render: (order, record) => (
					<Link to={'/orders/' + order.orderId}>
						<ExpandAltOutlined title={t('orders.Ver Detalles')} />
					</Link>
				),
			},
		]);
	}, [checkedOrders, i18n.language]);

	useEffect(() => {
		saveToLocalStorage('order.searchInput', searchInput);
	}, [searchInput]);

	useEffect(() => {
		saveToLocalStorage('order.businessIds', businessIds);
	}, [businessIds]);

	useEffect(() => {
		saveToLocalStorage('order.clientIds', clientIds);
	}, [clientIds]);

	useEffect(() => {
		saveToLocalStorage('order.employeeIds', employeeIds);
	}, [employeeIds]);

	useEffect(() => {
		saveToLocalStorage('order.selectedStatusCodes', selectedStatusCodes);
	}, [selectedStatusCodes]);

	const filterProps = {
		handleSearch,

		setClientIds,
		clientIds,

		setEmployeeIds,
		employeeIds,

		setBusinessIds,
		businessIds,

		setSearchInput,
		searchInput,

		setSelectedStatusCodes,
		selectedStatusCodes,
	};

	const openPdfs = async () => {
		setLoading(true);
		const response = await get(
			API_URL_PODS_PDF,
			{ orderIds: checkedOrders.join(',') },
			{
				responseType: 'blob',
			}
		);
		setLoading(false);
		if (
			response.data &&
			response.headers?.['content-type'] === 'application/pdf'
		) {
			if (response?.data) {
				const file = new Blob([response.data], { type: 'application/pdf' });
				const fileURL = URL.createObjectURL(file);
				window.open(fileURL, '_blank');
			}
		}
	};

	return (
		<MainLayout>
			{!hideOrderFilters && (
				<div className="orders-filters">
					<form onKeyDown={handleKeyPress}>
						<AccordionFilters filterProps={filterProps} />
					</form>
				</div>
			)}
			{loading && <Spin size="large" />}
			{error && (
				<p>
					{t('orders.Error')}: {error}
				</p>
			)}
			{!loading && !error && (
				<>
					{selectedOrder ? (
						<OrderDetails
							details={details}
							closeDetails={closeDetails}
							selectedOrder={selectedOrder}
						/>
					) : (
						<>
							<div className="orders-footer">
								<Button onClick={handleGoToStart}>
									{t('orders.Ir al inicio')}
								</Button>
								<Button
									onClick={handlePreviousPage}
									disabled={page <= 1 || isLoadingMore}
								>
									{t('previousPage')}
								</Button>
								<Button
									onClick={loadMoreOrders}
									disabled={!hasMorePages || isLoadingMore}
									loading={loading}
								>
									{t('nextPage')}
								</Button>
								<Paginator perPage={perPage} setPerPage={setPerPage} />
							</div>
							{checkedOrders?.length > 0 && (
								<Flex gap="small" align="center" wrap>
									<Button
										type="primary"
										icon={<FilePdfOutlined />}
										onClick={() => openPdfs()}
									></Button>

									<Button
										type="primary"
										icon={<DeleteOutlined />}
										onClick={() => cleanOrderPdfs()}
									></Button>
								</Flex>
							)}
							<div className="orders-table">
								<Table
									dataSource={orders}
									columns={columns}
									pagination={false}
									rowKey="orderId"
								/>
							</div>
							<div className="orders-footer">
								<Button onClick={handleGoToStart}>
									{t('orders.Ir al inicio')}
								</Button>
								<Button
									onClick={handlePreviousPage}
									disabled={page <= 1 || isLoadingMore}
								>
									{t('previousPage')}
								</Button>
								<Button
									onClick={loadMoreOrders}
									disabled={!hasMorePages || isLoadingMore}
									loading={loading}
								>
									{t('nextPage')}
								</Button>
								<Paginator perPage={perPage} setPerPage={setPerPage} />
							</div>
						</>
					)}
				</>
			)}
		</MainLayout>
	);
}
