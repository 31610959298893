import React, { useEffect } from 'react';

const ZendeskWidget = () => {
	// useEffect(() => {
	// 	const script = document.createElement('script');
	// 	script.id = 'ze-snippet';
	// 	script.src =
	// 		'https://static.zdassets.com/ekr/snippet.js?key=b8ef26b6-a722-44d1-9b7b-1bc6c53881c9';
	// 	script.async = true;
	// 	document.body.appendChild(script);

	// 	return () => {
	// 		document.body.removeChild(script);
	// 	};
	// }, []);

	return null;
};

export default ZendeskWidget;
