import React, { createContext, useState, useContext } from 'react';
import {
	getFromLocalStorage,
	removeFromLocalStorage,
	saveToLocalStorage,
} from '../Dashboard/Login/storage';

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
	const [user, setUser] = useState(getFromLocalStorage('user'));

	const login = (userData) => {
		setUser(userData);
		saveToLocalStorage('user', userData);
	};

	const logout = () => {
		setUser(undefined);
		removeFromLocalStorage('user');
	};

	const updateUser = (updatedUser) => {
		const newUser = { ...user, ...updatedUser };
		login(newUser);
	};

	const value = {
		user,
		login,
		logout,
		updateUser,
	};

	return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => {
	const context = useContext(UserContext);

	if (!context) {
		throw new Error('useUser must be used within a UserProvider');
	}
	return context;
};
