import React, { useEffect, useState, useRef } from 'react';
import useSound from 'use-sound';
import negative from './sounds/negative.mp3';
import success from './sounds/success.mp3';
import { Table, Tag, Space, Button, Input, Alert } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
	API_URL_ORDER_STATUS_CHANGE,
	API_URL_LABELS_PDF,
	get,
	post,
} from '../../App/Api';
import {
	historySaveToLocalStorage,
	loadFromLocalStorage,
} from '../Login/storage';
import MainLayout from '../../Layout/MainLayout';

export function LabelMultiple() {
	const { t, i18n } = useTranslation();

	const [orderIds, setOrderIds] = useState([]);
	const [pdfData, setPdfData] = useState(null);
	const [loading, setLoading] = useState(false);

	const [errorMessage, setErrorMessage] = useState('');
	const [reference, setReference] = useState('');
	const [responseMessage, setResponseMessage] = useState(null);
	const [playErrorSound] = useSound(negative);
	const [playSuccessSound] = useSound(success);

	const [orderLabel, setOrderLabel] = useState(
		loadFromLocalStorage('orderLabel') || []
	);

	const saveOrderLabelToLocalStorage = (newOrderLabels) => {
		setOrderLabel(newOrderLabels);
		historySaveToLocalStorage('orderLabel', newOrderLabels);
	};

	// LOCALSTORAGE
	useEffect(() => {
		if (Array.isArray(orderLabel)) {
			const newOrderIds = [];
			for (let index = 0; index < orderLabel.length; index++) {
				newOrderIds.push(orderLabel[index].orderId);
			}
			setOrderIds(newOrderIds);
		}
	}, [orderLabel]);

	const referenceInputRef = useRef(null);
	// Peticion
	const sendRequest = async () => {
		setReference('');
		setErrorMessage('');

		referenceInputRef.current.disabled = true;

		const trimmedReference = reference.trim();

		setLoading(true);
		try {
			const response = await post(API_URL_ORDER_STATUS_CHANGE, {
				orderStatusCode: 'SC',
				code: trimmedReference,
			});

			const newEntries = [...orderLabel];
			const newOrderIds = [...orderIds];
			const successes = [];
			const errors = [];

			for (let index = 0; index < response.data.length; index++) {
				const order = response.data[index];

				const newEntry = {
					reference: order.code,
					orderId: order.order?.orderId,
					message: order.message,
					error: order.error,
				};

				if (!newEntry.error) {
					if (!newOrderIds.includes(newEntry.orderId)) {
						newOrderIds.push(newEntry.orderId);
						if (order.error) {
							errors.push(newEntry);
						} else {
							successes.push(newEntry);
						}
						newEntries.push(newEntry);
					} else {
						newEntry.error = true;
						newEntry.message = `Referencia ${newEntry.reference} ya existe`;
						errors.push(newEntry);
					}
				} else {
					errors.push(newEntry);
				}

				if (errors.length) {
					const errorMessages = errors.map((error) => {
						return error.message;
					});

					setErrorMessage(errorMessages);
				}
			}

			saveOrderLabelToLocalStorage(newEntries);

			if (errors.length) {
				setResponseMessage({ error: true });
			} else {
				setResponseMessage({ error: false });
			}
		} catch (error) {
			setResponseMessage({ error: true });
			setErrorMessage(t('mrw.errors.requestError'));
		} finally {
			setLoading(false);
			referenceInputRef.current.disabled = false;
			referenceInputRef.current.focus();
		}
	};

	// EVENTO DE LA TECLA ENTER
	const keyDown = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			sendRequest();
		}
	};

	// EVENTO DE LA TECLA ENTER
	const onClickLabel = async (event) => {
		setPdfData('');
		setErrorMessage('');
		referenceInputRef.current.disabled = true;
		const trimmedReference = reference.trim();
		setLoading(true);

		try {
			const response = await get(
				API_URL_LABELS_PDF,
				{ orderIds: orderIds.join(',') },
				{
					responseType: 'blob',
				}
			);

			if (
				response.data &&
				response.headers?.['content-type'] === 'application/pdf'
			) {
				if (response?.data) {
					const file = new Blob([response.data], { type: 'application/pdf' });
					const fileURL = URL.createObjectURL(file);
					window.open(fileURL, '_blank');
				}
				setResponseMessage({ error: false });
			} else {
				setResponseMessage({ error: true });
			}
		} catch (error) {
			setResponseMessage({ error: true });
			setErrorMessage(t('mrw.errors.requestError'));
		} finally {
			setLoading(false);
			referenceInputRef.current.disabled = false;
			referenceInputRef.current.focus();
		}
	};

	// SONIDOS
	useEffect(() => {
		if (responseMessage !== null) {
			if (responseMessage.error) {
				playErrorSound();
			} else {
				playSuccessSound();
			}
		}
	}, [responseMessage, playErrorSound, playSuccessSound]);

	// Tabla
	const columnSuccess = [
		{
			title: t('columns.Reference'),
			dataIndex: 'reference',
			key: 'reference',
			width: 10,
			render: (code) => <Tag color="blue">{code}</Tag>,
		},
		{
			title: t('columns.Action'),
			key: 'action',
			width: 50,
			render: (text, record, index) => (
				<Space size="middle">
					<p
						onClick={() => deleteOrderLabel(index)}
						className={record.error ? 'error-delete' : 'success-delete'}
					>
						{t('columns.delete')}
					</p>
				</Space>
			),
		},
	];

	const deleteAllOrderLabel = () => {
		saveOrderLabelToLocalStorage([]);
	};

	const deleteOrderLabel = (index) => {
		const newOrderLabel = [...orderLabel];
		newOrderLabel.splice(index, 1);
		saveOrderLabelToLocalStorage(newOrderLabel);
	};

	return (
		<MainLayout>
			<Input
				type="text"
				id="mrw-input-reference"
				ref={referenceInputRef}
				className="mrw-input"
				style={{ width: '100%', margin: '10px' }}
				value={reference}
				onChange={(e) => setReference(e.target.value)}
				placeholder={t('input.Enter tracking number(s) here')}
				onKeyDown={keyDown}
			/>
			{errorMessage && <Alert message={errorMessage} type="warning" showIcon />}
			<div className="success-message">
				<Button
					disabled={loading}
					icon={<PrinterOutlined />}
					type="primary"
					onClick={onClickLabel}
				>
					{t('mrw.buildLabels')}
				</Button>
				<Button type="primary" danger onClick={() => deleteAllOrderLabel()}>
					{t('columns.delete')}
				</Button>
				<Table
					className="ant-table-small"
					columns={columnSuccess}
					dataSource={orderLabel}
				/>
			</div>
			{pdfData && (
				<iframe src={pdfData} onLoad={() => {}} width={'100%'} height={600} />
			)}
		</MainLayout>
	);
}
