import { I18nextProvider } from 'react-i18next';
import i18n from '../utils/i18n';
import './index.css';
import { Navbar } from '../Navbar';
import { Busqueda } from './Busqueda';

function Tracking() {
	return (
		<I18nextProvider i18n={i18n}>
			<Navbar />
			<Busqueda />
		</I18nextProvider>
	);
}

export { Tracking };
