import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { API_URL_ORDERS_CITIES_SELET, get } from '../../../App/Api';

export function CitiesFilterForecast({ setCities }) {
	const { t, i18n } = useTranslation();

	const [city, setCity] = useState({});

	useEffect(() => {
		fetchCities();
	}, [i18n.language]);

	const fetchCities = async () => {
		try {
			const response = await get(API_URL_ORDERS_CITIES_SELET);

			const idMap = {};
			response.data.forEach((city) => {
				idMap[city.cityName] = city.cityName;
			});

			setCity(idMap);
		} catch (error) {
			console.error('Error fetching clients:', error);
		}
	};

	const handleStateChange = (names) => {
		const selected = names
			.map((name) => city[name])
			.filter((id) => id !== undefined);
		setCities(selected);
	};

	return (
		<Select
			mode="multiple"
			onChange={handleStateChange}
			defaultValue={[]}
			style={{ width: '100%', margin: '10px 0' }}
			placeholder={t('filters.cities')}
		>
			{Object.keys(city).map((name) => (
				<Select.Option key={name} value={name}>
					{name}
				</Select.Option>
			))}
		</Select>
	);
}
