import React from 'react';
import box from './img/box.png';
import './main.css';
import { Progress, Tooltip } from 'antd';

export function ProgressBar({ order }) {
	return (
		<Tooltip title={order?.currentStatus}>
			<Progress
				type="circle"
				percent={order?.timeline?.progress}
				status={
					['DLV'].includes(order?.detailStatus?.[0]?.code)
						? 'success'
						: ['FLD', 'LST'].includes(order?.detailStatus?.[0]?.code)
						? 'exception'
						: undefined
				}
				// "normal" | "exception" | "active" | "success"
				// success={{
				// 	percent: 30,
				// }}
			/>
		</Tooltip>
		// <div className="progress">
		// 	<div className="progress__container">
		// 		<div className="progress__bar">
		// 			<div
		// 				className="progress-bar-inner"
		// 				style={{
		// 					width: `${Math.min(timeline.progress, 100)}%`,
		// 					maxWidth: '100%',
		// 					overflow: 'hidden',
		// 				}}
		// 			>
		// 				<div className="process-bar-dot-wrap">{renderedUsers}</div>
		// 				<div
		// 					className="process-package-img-content"
		// 					style={{
		// 						left: `${Math.min(timeline.progress, 100)}%`,
		// 						top: '27%',
		// 						overflow: 'hidden',
		// 					}}
		// 				>
		// 					<div className="process-package-icon-normal"></div>
		// 					<img src={box} alt="Car" className="process-package-img" />
		// 				</div>
		// 			</div>
		// 		</div>
		// 	</div>
		// </div>
	);
}
