import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { API_URL_ORDERS_STATES_SELET, get } from '../../../App/Api';

export function StatesFilterForecast({ setStates }) {
	const { t, i18n } = useTranslation();

	const [state, setState] = useState({});

	useEffect(() => {
		fetchStates();
	}, [i18n.language]);

	const fetchStates = async () => {
		try {
			const response = await get(API_URL_ORDERS_STATES_SELET);

			const idMap = {};
			response.data.forEach((state) => {
				idMap[state.stateName] = state.stateName;
			});

			setState(idMap);
		} catch (error) {
			console.error('Error fetching clients:', error);
		}
	};

	const handleStateChange = (names) => {
		const selected = names
			.map((name) => state[name])
			.filter((id) => id !== undefined);
		setStates(selected);
	};

	return (
		<Select
			mode="multiple"
			onChange={handleStateChange}
			defaultValue={[]}
			style={{ width: '100%', margin: '10px 0' }}
			placeholder={t('filters.states')}
		>
			{Object.keys(state).map((name) => (
				<Select.Option key={name} value={name}>
					{name}
				</Select.Option>
			))}
		</Select>
	);
}
