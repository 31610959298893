import React, { useState } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

export function PostalCodesFilterForecast({ setPostalCodes }) {
	const { t } = useTranslation();

	const [postalcode] = useState({});

	const handleStateChange = (names) => {
		setPostalCodes(names);
	};

	return (
		<Select
			mode="tags"
			onChange={handleStateChange}
			defaultValue={[]}
			style={{ width: '100%', margin: '10px 0' }}
			placeholder={t('filters.postalcodes')}
		>
			{Object.keys(postalcode).map((name) => (
				<Select.Option key={name} value={name}>
					{name}
				</Select.Option>
			))}
		</Select>
	);
}
