import React, { useEffect, useState } from 'react';
import { API_URL_FLEETS, patch, get, post } from '../../../App/Api';
import {
	Alert,
	Button,
	Card,
	Checkbox,
	Col,
	Form,
	Input,
	message,
	Row,
} from 'antd';
import { useTranslation } from 'react-i18next';
import Title from 'antd/es/typography/Title';

export function FleetForm({ fleetId = undefined, onClose }) {
	const { t, i18n } = useTranslation();
	const [fleets, setFleets] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		fetch();
	}, []);

	const fetch = async () => {
		try {
			if (fleetId) {
				const response = await get(`${API_URL_FLEETS}${fleetId.fleetId}`);
				setFleets(response.data);
			} else {
				setFleets({ active: true });
			}
		} catch (error) {
			setError(t('fleetsEdit.error'), error);
		} finally {
			setLoading(false);
		}
	};

	const handleSubmit = async (form) => {
		try {
			let response;
			if (fleetId?.fleetId) {
				response = await patch(`${API_URL_FLEETS}${fleetId.fleetId}`, form);
			} else {
				response = await post(`${API_URL_FLEETS}`, form);
			}

			message.open({
				type: 'success',
				content: t('fleetsEdit.success'),
			});

			onClose();
		} catch (error) {
			setError(error?.response?.data?.message);
		}
	};

	if (loading) {
		return <p>{t('fleetsEdit.loading')}</p>;
	}

	return (
		<div>
			{error && <Alert message={error} type="warning" showIcon />}
			<Row justify="center" align="middle">
				<Col
					xs={{ flex: '100%' }}
					sm={{ flex: '90%' }}
					md={{ flex: '80%' }}
					lg={{ flex: '60%' }}
					xl={{ flex: '50%' }}
				>
					<Card>
						<Form
							labelCol={{
								span: 8,
							}}
							wrapperCol={{
								span: 16,
							}}
							initialValues={{
								name: fleets?.name,
								code: fleets?.code,
								active: fleets?.active,
							}}
							onFinish={handleSubmit}
							autoComplete="off"
						>
							<Form.Item
								label={t('fleetsEdit.name')}
								name="name"
								rules={[
									{
										required: true,
									},
								]}
							>
								<Input />
							</Form.Item>

							<Form.Item
								label={t('fleetsEdit.code')}
								name="code"
								rules={[
									{
										required: true,
										message: 'Please input your password!',
									},
								]}
							>
								<Input />
							</Form.Item>

							<Form.Item
								name="active"
								valuePropName="checked"
								label={t('fleetsEdit.active')}
							>
								<Checkbox></Checkbox>
							</Form.Item>

							<Form.Item style={{ textAlign: 'center' }} wrapperCol={24}>
								<Button type="primary" danger onClick={onClose}>
									{t('fleetsEdit.cancel')}
								</Button>
								<Button type="primary" htmlType="submit">
									{t('fleetsEdit.save')}
								</Button>
							</Form.Item>
						</Form>
					</Card>
				</Col>
			</Row>
		</div>
	);
}
