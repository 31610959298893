import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { Option } from 'antd/es/mentions';
import { API_URL_FLEETS_CITIES_SELECT, get } from '../../App/Api';
import { useTranslation } from 'react-i18next';

export function FleetCityFilter({
	mode = 'multiple',
	setFleetCities,
	fleetCities = [],
}) {
	const { t, i18n } = useTranslation();

	const [options, setOptions] = useState({});

	useEffect(() => {
		fetchFleetCitys();
	}, [i18n.language]);

	const fetchFleetCitys = async () => {
		try {
			const response = await get(API_URL_FLEETS_CITIES_SELECT);
			let newOptions = [];

			if (response?.data?.length) {
				newOptions = response.data.map((city) => {
					return {
						value: city.cityName,
						label: city.cityName,
					};
				});
			}
			setOptions(newOptions);
		} catch (error) {
			console.error('Error fetching clients:', error);
		}
	};

	const handleOptionsChange = (value) => {
		setFleetCities(value);
	};

	return (
		<Select
			showSearch
			mode={mode}
			optionFilterProp="label"
			onChange={handleOptionsChange}
			defaultValue={fleetCities}
			style={{ width: '100%', margin: '10px 0' }}
			placeholder={t('filters.cities')}
			options={options}
		></Select>
	);
}
