import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	getFromLocalStorage,
	saveToLocalStorage,
} from '../Dashboard/Login/storage';
import { Select, Space } from 'antd';

export const LenguajeSelect = () => {
	const { t, i18n } = useTranslation();
	const language =
		getFromLocalStorage('i18n') || navigator.language?.split('-')?.[0];

	useEffect(() => {
		handleChange(language);
	}, []);

	const options = [
		{
			label: t('navbar.español'),
			value: 'es',
			emoji: 'es',
			desc: t('navbar.español'),
		},
		{
			label: t('navbar.english'),
			value: 'en',
			emoji: '🇺🇸',
			desc: t('navbar.english'),
		},
		{
			label: t('navbar.chinese'),
			value: 'zh',
			emoji: '🇨🇳',
			desc: t('navbar.chinese'),
		},
	];

	const handleChange = (value) => {
		i18n.changeLanguage(value);
		saveToLocalStorage('i18n', value);
		document.documentElement.setAttribute('lang', value);
	};

	return (
		<Select
			placeholder={t('lenguage')}
			defaultValue={[language]}
			onChange={handleChange}
			options={options}
			optionRender={(option) => (
				<Space>
					<span role="img" aria-label={option.data.label}>
						{option.data.emoji}
					</span>
					{option.data.desc}
				</Space>
			)}
		></Select>
	);
};
