import { Empty } from 'antd';
import MainLayout from '../Layout/MainLayout';

export function NotFound() {
	return (
		<MainLayout>
			<Empty />
		</MainLayout>
	);
}
