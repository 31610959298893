import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFromLocalStorage } from '../Dashboard/Login/storage';

function ProtectedRoute({ element, allowedRoles = [] }) {
	const [user, setUser] = useState(getFromLocalStorage('user'));
	const navigate = useNavigate();

	useEffect(() => {
		const newUser = getFromLocalStorage('user');
		if (!newUser) {
			setUser();
			navigate('/login');
		}

		if (allowedRoles?.length) {
			if (!allowedRoles.includes(user?.roleId)) {
				navigate('/not-found');
			}
		}
	}, [navigate]);

	return user ? element : null;
}

export default ProtectedRoute;
