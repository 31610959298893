import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	API_URL_EMPLOYEES,
	API_URL_FLEETS_SELECT,
	get,
	patch,
	post,
} from '../../../App/Api';
import { timeZones } from '../../Business/TimeZone';
import {
	Alert,
	Button,
	Card,
	Checkbox,
	Col,
	Form,
	Input,
	message,
	Row,
	Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { FleetFilter } from '../../../Fleet/Filter';

export function EmployeeForm({ employeeId = undefined, onClose }) {
	const { t, i18n } = useTranslation();

	const [employee, setEmployee] = useState(null);
	const [loading, setLoading] = useState(true);
	const [fleet, setFleet] = useState({});
	const [error, setError] = useState(null);
	const [form] = Form.useForm();

	useEffect(() => {
		fetch();
	}, []);

	const fetch = async () => {
		try {
			if (employeeId) {
				const response = await get(API_URL_EMPLOYEES + employeeId.employeeId);
				const employee = response.data;
				employee.code = employee.user?.code;
				employee.nickname = employee.user?.nickname;
				employee.timeZone = employee.user?.timeZone;
				employee.email = employee.user?.email;
				setEmployee(employee);
			} else {
				setEmployee({
					password: 'MMGpsw0!',
					timeZone: 'Europe/Madrid',
					active: true,
				});
			}
		} catch (error) {
			setError(error?.response?.data?.message);
		} finally {
			setLoading(false);
		}
	};

	const setFleetIds = (fleetId) => {
		const newEmployee = employee;
		form.fleetId = fleetId;
		newEmployee.fleetId = fleetId;
		form.setFieldsValue({ fleetId });
	};

	const handleSubmit = async (form) => {
		setError();
		try {
			if (employeeId?.employeeId) {
				await patch(`${API_URL_EMPLOYEES}${employeeId.employeeId}`, form);
			} else {
				await post(`${API_URL_EMPLOYEES}`, form);
			}

			message.open({
				type: 'success',
				content: t('employees.success'),
			});

			onClose();
		} catch (error) {
			setError(error?.response?.data?.message);
		}
	};

	if (loading) {
		return <p>Loading employee data...</p>;
	}

	return (
		<div>
			{error && <Alert message={error} type="warning" showIcon />}
			<Row justify="center" align="middle">
				<Col
					xs={{ flex: '100%' }}
					sm={{ flex: '90%' }}
					md={{ flex: '80%' }}
					lg={{ flex: '60%' }}
					xl={{ flex: '50%' }}
				>
					<Card>
						<Form
							form={form}
							labelCol={{
								span: 8,
							}}
							wrapperCol={{
								span: 16,
							}}
							initialValues={{
								name: employee?.name,
								email: employee?.email,
								fleetId: employee?.fleetId,
								nickname: employee?.nickname,
								active: employee?.active,
								password: employee?.password,
								timeZone: employee?.timeZone,
							}}
							onFinish={handleSubmit}
							autoComplete="off"
						>
							<Form.Item
								label={t('employees.employeeFleetsId')}
								name="fleetId"
								rules={[
									{
										required: true,
									},
								]}
							>
								<FleetFilter
									mode={null}
									setFleetIds={setFleetIds}
									fleetIds={[employee.fleetId]}
								/>
							</Form.Item>

							<Form.Item
								label={t('employees.name')}
								name="name"
								rules={[
									{
										required: true,
									},
								]}
							>
								<Input />
							</Form.Item>

							<Form.Item
								label={t('employees.nickname')}
								name="nickname"
								rules={[
									{
										required: true,
									},
								]}
							>
								<Input />
							</Form.Item>

							<Form.Item
								label={t('employees.email')}
								name="email"
								rules={[
									{
										required: true,
									},
									{ type: 'email' },
								]}
							>
								<Input />
							</Form.Item>

							<Form.Item
								label={t('employees.password')}
								name="password"
								rules={[
									{ min: 6 },
									{ pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).*/ },
								]}
							>
								<Input.Password />
							</Form.Item>

							<Form.Item label={t('employees.timeZone')} name="timeZone">
								<Select showSearch>
									{timeZones.map((timezone) => (
										<Select.Option key={timezone} value={timezone}>
											{timezone}
										</Select.Option>
									))}
								</Select>
							</Form.Item>

							<Form.Item
								name="active"
								valuePropName="checked"
								label={t('employees.active')}
							>
								<Checkbox></Checkbox>
							</Form.Item>

							<Form.Item style={{ textAlign: 'center' }} wrapperCol={24}>
								<Button type="primary" danger onClick={onClose}>
									{t('employees.cancel')}
								</Button>
								<Button type="primary" htmlType="submit">
									{t('employees.save')}
								</Button>
							</Form.Item>
						</Form>
					</Card>
				</Col>
			</Row>
		</div>
	);
}
