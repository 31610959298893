import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { API_URL_BUSINESS_SELECT, get } from '../../App/Api';
import { useTranslation } from 'react-i18next';

export function BusinessFilter({
	mode = 'multiple',
	setBusinessIds,
	businessIds = [],
}) {
	const { t, i18n } = useTranslation();
	const [options, setOptions] = useState({});

	useEffect(() => {
		fetchBusinesss();
	}, [i18n.language]);

	const fetchBusinesss = async () => {
		try {
			const response = await get(API_URL_BUSINESS_SELECT);
			let newOptions = [];

			if (response?.data?.length) {
				newOptions = response.data.map((business) => {
					return {
						value: business.businessId,
						label: business.name,
					};
				});
			}
			setOptions(newOptions);
		} catch (error) {
			console.error('Error fetching businesss:', error);
		}
	};

	const handleOptionsChange = (value) => {
		setBusinessIds(value);
	};

	return (
		<Select
			showSearch
			mode={mode}
			optionFilterProp="label"
			onChange={handleOptionsChange}
			defaultValue={businessIds}
			style={{ width: '100%', margin: '10px 0' }}
			placeholder={t('filters.businesss')}
			options={options}
		></Select>
	);
}
