import React, { useEffect, useState } from 'react';
import { Table, Spin, Alert, Button, message, Form, Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { API_URL_FOLLOWING, get } from '../../App/Api';

import { FollowingAccordeonFilters } from './Accordeon/Filters';
import { saveToLocalStorage } from '../Login/storage';
import { Paginator } from '../../Paginator';
import { FollowingDetails } from './Details';
import MainLayout from '../../Layout/MainLayout';

export function Following() {
	const { t, i18n } = useTranslation();
	const [following, setFollowing] = useState([]);
	const [perPage, setPerPage] = useState(10);
	const [page, setPage] = useState(1);
	const [employeeIds, setEmployeeIds] = useState([]);
	const [date, setDate] = useState(null);
	const [fleetIds, setFleetIds] = useState([]);
	const [fleetCities, setFleetCities] = useState([]);
	const [fleetStates, setFleetStates] = useState([]);
	const [searchReference, setSearchReference] = useState(null);
	const [clientIds, setClientIds] = useState([]);
	const [hasMorePages, setHasMorePages] = useState(true);
	const [isLoadingMore, setIsLoadingMore] = useState(false);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();
	const [selectedEmployee, setSelectedEmployee] = useState(null);

	const openFollowingDetails = (employee) => {
		setSelectedEmployee(employee);
	};

	const closeFollowingDetails = () => {
		setSelectedEmployee(null);
	};

	useEffect(() => {
		fetchData();
	}, [perPage, page, i18n.language]);

	const fetchData = async () => {
		setLoading(true);

		try {
			let url = API_URL_FOLLOWING;

			const response = await get(url, {
				perPage,
				page,
				date,
				fleetIds,
				fleetCities,
				fleetStates,
				references: searchReference,
				employeeIds,
				clientIds,
			});

			if (Array.isArray(response.data)) {
				setHasMorePages(response.data.length === perPage);
				setFollowing(response.data);
				setError(null);
			} else {
				throw new Error('Data format is incorrect');
			}
		} catch (error) {
			setError(error?.message);
		} finally {
			setLoading(false);
			setIsLoadingMore(false);
		}
	};

	const handleAddFollowing = () => {};

	const loadMoreFollowing = () => {
		if (hasMorePages && !isLoadingMore) {
			setIsLoadingMore(true);
			setPage((prevPage) => prevPage + 1);
		}
	};

	const handlePreviousPage = () => {
		if (page > 1) {
			setPage((prevPage) => prevPage - 1);
		}
	};

	const handleSearch = () => {
		setPage(1);
		fetchData();
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			handleSearch();
		}
	};
	const handleInputOrderSearch = (value) => {
		if (!value) {
			value = null;
		}
		setSearchReference(value);
		saveToLocalStorage('following.searchReference', value);
	};

	const columns = [
		{
			title: '#',
			dataIndex: 'position',
			key: 'position',
			align: 'center',
			render: (text, record, index) => perPage * (page - 1) + index + 1,
		},
		{
			title: t('fleets.name'),
			dataIndex: 'fleetName',
			key: 'fleetName',
			align: 'center',
		},
		{
			title: t('employees.name'),
			dataIndex: 'employeeName',
			key: 'employeeName',
			align: 'center',
		},
		{
			title: t('orders.qty'),
			dataIndex: 'orders',
			key: 'orders',
			align: 'center',
		},
		// {
		// 	title: t('postalCodes.qty'),
		// 	dataIndex: 'postalCodes',
		// 	key: 'postalCodes',
		// 	align: 'center',
		// },
		{
			title: t('orderStatus.FLD.name'),
			dataIndex: 'failed',
			key: 'failed',
			align: 'center',
			render: (text) => text || '0',
		},
		{
			title: t('actions'),
			key: 'actions',
			render: (employee) => (
				<Space>
					<Button onClick={() => openFollowingDetails(employee)}>
						{t('following.detail')}
					</Button>
				</Space>
			),
		},
	];

	const filterProps = {
		setDate,
		setFleetIds,
		setFleetCities,
		setFleetStates,
		setEmployeeIds,
		setClientIds,
		setPerPage,
		setPage,
		handleAddFollowing,
		handleSearch,
		handleInputOrderSearch,
	};

	return (
		<MainLayout>
			{error && (
				<Alert message="Error" description={error} type="error" showIcon />
			)}

			<div
				className="filters"
				style={{ display: !selectedEmployee ? 'block' : 'none' }}
			>
				<form onKeyDown={handleKeyPress}>
					<FollowingAccordeonFilters filterProps={filterProps} />
				</form>
			</div>
			{selectedEmployee && (
				<FollowingDetails
					closeFollowingDetails={closeFollowingDetails}
					selectedEmployee={selectedEmployee}
				/>
			)}
			{!selectedEmployee && (
				<>
					{loading ? (
						<Spin size="large" />
					) : (
						<>
							<div className="following-footer">
								<Button onClick={handlePreviousPage} disabled={page === 1}>
									{t('previousPage')}
								</Button>
								<Button
									onClick={loadMoreFollowing}
									disabled={!hasMorePages || isLoadingMore}
								>
									{t('nextPage')}
								</Button>
								<Paginator perPage={perPage} setPerPage={setPerPage} />
							</div>
							<div className="following-table">
								<Table
									dataSource={following}
									columns={columns}
									rowKey="following"
									pagination={false}
								/>
							</div>
						</>
					)}
				</>
			)}
		</MainLayout>
	);
}
