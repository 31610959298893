import React, { useEffect, useState } from 'react';
import useSound from 'use-sound';
import {
	historySaveToLocalStorage,
	loadFromLocalStorage,
} from '../Login/storage';
import negative from '../../Assets/Sounds/negative.mp3';
import success from '../../Assets/Sounds/success.mp3';
import succesMoreAttempts from '../../Assets/Sounds/succes_more_attempts.mp3';
import {
	Select,
	Table,
	Tag,
	Space,
	Button,
	Row,
	Col,
	Input,
	Alert,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
	API_URL_ORDER_STATUS_CHANGE,
	API_URL_PICKING_OPTIONS,
	get,
	post,
} from '../../App/Api';
import { EmployeeFilter } from '../../Employee/Filter';
import MainLayout from '../../Layout/MainLayout';

export function Picking() {
	const { t, i18n } = useTranslation();

	const [options, setOptions] = useState([]);
	const [employeeId, setEmployeeId] = useState();
	const [selectedOption, setSelectedOption] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const [reference, setReference] = useState('');
	const [errorReferences, setErrorReferences] = useState([]);
	const [successReferences, setSuccessReferences] = useState([]);
	const [responseMessage, setResponseMessage] = useState(null);
	const [history, setHistory] = useState([]);
	const [count, setCount] = useState(0);
	const [playErrorSound] = useSound(negative);
	const [playSuccessSound] = useSound(success);
	const [playSuccesMoreAttemptsSound] = useSound(succesMoreAttempts);

	// LOCALSTORAGE
	useEffect(() => {
		const savedHistory = loadFromLocalStorage('pickingHistory');
		if (savedHistory) {
			setHistory(savedHistory);
		}

		const storedErrorReferences = loadFromLocalStorage('errorReferences');
		if (Array.isArray(storedErrorReferences)) {
			setErrorReferences(storedErrorReferences);
		}

		const storedSuccessReferences = loadFromLocalStorage('successReferences');
		if (Array.isArray(storedSuccessReferences)) {
			setSuccessReferences(storedSuccessReferences);
		}

		const storedCount = localStorage.getItem('count');
		if (storedCount) {
			setCount(parseInt(storedCount));
		}
	}, []);

	const saveHistoryToLocalStorage = (newHistory) => {
		historySaveToLocalStorage('pickingHistory', newHistory);
	};

	const saveErrorReferencesToLocalStorage = (newErrorReferences) => {
		historySaveToLocalStorage('errorReferences', newErrorReferences);
	};

	const saveSuccessReferencesToLocalStorage = (newSuccessReferences) => {
		historySaveToLocalStorage('successReferences', newSuccessReferences);
	};

	// INCREMENTEO DEL ID
	const incrementCount = () => {
		setCount((prevCount) => prevCount + 1);
		localStorage.setItem('count', count + 1);
	};

	// RESET
	const resetCount = () => {
		setCount(0);
		localStorage.setItem('count', 0);
	};

	const optionChange = (value) => {
		setEmployeeId();
		setSelectedOption(value);
	};

	// REFERENCIAS
	const sendRequest = async () => {
		setReference('');

		if (!selectedOption) {
			playErrorSound();
			setErrorMessage(t('picking.errors.selectOption'));
			return;
		}

		if (selectedOption === 'ASN' && !employeeId) {
			playErrorSound();
			setErrorMessage(t('picking.errors.selectEmployee'));
			return;
		}

		const trimmedReference = reference.trim();

		if (history.some((entry) => entry.code.includes(trimmedReference))) {
			playErrorSound();
			setErrorMessage(t('picking.errors.referenceExists'));
			return;
		}

		setLoading(true);

		try {
			const response = await post(API_URL_ORDER_STATUS_CHANGE, {
				orderStatusCode: selectedOption,
				code: trimmedReference,
				employeeId,
			});

			let moreAttempts = false;
			const newEntries = [];
			const successes = [];
			const errors = [];

			for (let index = 0; index < response.data.length; index++) {
				const order = response.data[index];
				const newEntry = {
					code: order.code,
					name: order?.order?.orderStatusCode
						? t('orderStatus.' + order?.order?.orderStatusCode + '.name')
						: options.find(
								(option) => option.orderStatusCode === selectedOption
						  )?.name,
					attemptCounter: order?.order?.attemptCounter
						? order.order.attemptCounter
						: 0,
					message: order.message,
					error: order.error,
					number: count + 1,
				};

				if (order.error) {
					errors.unshift(newEntry);
				} else {
					if (!moreAttempts && newEntry.attemptCounter >= 2) {
						moreAttempts = true;
					}
					successes.unshift(newEntry);
				}

				newEntries.unshift(newEntry);
			}

			const newHistory = newEntries.concat(history);
			setHistory(newHistory);
			saveHistoryToLocalStorage(newHistory);
			incrementCount();

			if (errors.length) {
				const newErrorReferences = errors.concat(errorReferences);
				setErrorReferences(newErrorReferences);
				saveErrorReferencesToLocalStorage(newErrorReferences);
			}

			if (successes.length) {
				const newSuccessReferences = successes.concat(successReferences);
				setSuccessReferences(newSuccessReferences);
				saveSuccessReferencesToLocalStorage(newSuccessReferences);
			}

			setResponseMessage({
				error: errors.length,
				moreAttempts,
				success: successes.length,
			});
			setErrorMessage(null);
			setReference('');
		} catch (error) {
			setResponseMessage({ error: true });
			setErrorMessage(
				error?.response?.data?.message || t('picking.errors.requestError')
			);
		} finally {
			setLoading(false);
		}
	};

	// OPCIONES DEL SELECTED
	const fetchOptions = async () => {
		try {
			setLoading(true);
			const response = await get(API_URL_PICKING_OPTIONS);
			setOptions(response.data);
		} catch (error) {
			setErrorMessage(t('picking.errors.requestError'));
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchOptions();
	}, [i18n.language]);

	// BORRAR REFERENCIAS
	const deleteAllErrors = () => {
		const newSuccessReferences = [...successReferences];

		setErrorReferences([]);
		saveErrorReferencesToLocalStorage([]);

		setHistory(newSuccessReferences);
		saveHistoryToLocalStorage(newSuccessReferences);
	};

	const deleteAllSuccess = () => {
		const newErrorReferences = [...errorReferences];
		setSuccessReferences([]);
		saveSuccessReferencesToLocalStorage([]);
		setHistory(newErrorReferences);
		saveHistoryToLocalStorage(newErrorReferences);
	};
	const deleteReference = (index, isError) => {
		const newSuccessReferences = [...successReferences];
		const newErrorReferences = [...errorReferences];

		if (isError) {
			newErrorReferences.splice(index, 1);
			setErrorReferences(newErrorReferences);
			saveErrorReferencesToLocalStorage(newErrorReferences);
		} else {
			newSuccessReferences.splice(index, 1);
			setSuccessReferences(newSuccessReferences);
			saveSuccessReferencesToLocalStorage(newSuccessReferences);
		}

		const newHistory = newSuccessReferences.concat(newErrorReferences);
		setHistory(newHistory);
		saveHistoryToLocalStorage(newHistory);
		resetCount();
	};

	// EVENTO DE LA TECLA ENTER
	const keyDown = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			sendRequest();
		}
	};

	// SONIDOS
	useEffect(() => {
		if (responseMessage !== null) {
			if (responseMessage.error) {
				playErrorSound();
			} else if (responseMessage.moreAttempts) {
				playSuccesMoreAttemptsSound();
			} else {
				playSuccessSound();
			}
		}
	}, [responseMessage, playErrorSound, playSuccessSound]);

	const columnSuccess = [
		{
			title: t('columns.#'),
			dataIndex: 'number',
			key: 'number',
			render: (text, record, index) => successReferences.length - index,
		},
		{
			title: t('columns.Reference'),
			dataIndex: 'code',
			key: 'code',
			render: (code) => <Tag color="blue">{code}</Tag>,
		},
		{
			title: t('columns.Status'),
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: t('columns.attempts'),
			dataIndex: 'attemptCounter',
			key: 'attemptCounter',
		},
		{
			title: t('columns.Action'),
			key: 'action',
			render: (text, record, index) => (
				<Space size="middle">
					<p
						onClick={() => deleteReference(index, record.error)}
						className={record.error ? 'error-delete' : 'success-delete'}
					>
						{t('columns.delete')}
					</p>
				</Space>
			),
		},
	];

	const columnError = [
		{
			title: t('columns.#'),
			dataIndex: 'number',
			key: 'number',
			render: (text, record, index) => errorReferences.length - index,
		},
		{
			title: t('columns.Reference'),
			dataIndex: 'code',
			key: 'code',
			render: (code) => <Tag color="blue">{code}</Tag>,
		},
		{
			title: t('columns.Status'),
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: t('columns.Message'),
			dataIndex: 'message',
			key: 'message',
		},
		{
			title: t('columns.Action'),
			key: 'action',
			render: (text, record, index) => (
				<Space size="middle">
					<p
						onClick={() => deleteReference(index, record.error)}
						className={record.error ? 'error-delete' : 'success-delete'}
					>
						{t('columns.delete')}
					</p>
				</Space>
			),
		},
	];

	return (
		<MainLayout>
			<Row
				gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
				justify="space-around"
				align="middle"
			>
				<Col className="gutter-row" span={18}>
					<Select
						className="picking-select"
						style={{ width: '100%', margin: '10px 0' }}
						value={selectedOption}
						onChange={optionChange}
						onKeyDown={keyDown}
						showSearch
						filterOption={(inputValue, option) =>
							option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >=
							0
						}
					>
						<Select.Option value="">{t('picking.selectOption')}</Select.Option>
						{options.map((option) => (
							<Select.Option
								key={option.orderStatusCode}
								value={option.orderStatusCode}
							>
								{t(`${option.name}`)}
							</Select.Option>
						))}
					</Select>
				</Col>
				{selectedOption === 'ASN' && (
					<Col span={18}>
						<EmployeeFilter
							setEmployeeIds={setEmployeeId}
							mode=""
						></EmployeeFilter>
					</Col>
				)}
				<Col span={18}>
					<Input
						type="text"
						className="picking-input"
						style={{ width: '100%', margin: '10px 0' }}
						value={reference}
						onChange={(e) => setReference(e.target.value)}
						placeholder={t('input.references')}
						onKeyDown={keyDown}
					/>
				</Col>
			</Row>

			{errorMessage && <Alert message={errorMessage} type="warning" showIcon />}

			<Row>
				<Col
					xs={{ flex: '100%' }}
					sm={{ flex: '100%' }}
					md={{ flex: '50%' }}
					lg={{ flex: '50%' }}
					xl={{ flex: '50%' }}
					style={{ border: '2px solid #52c41a' }}
				>
					<Button type="primary" danger onClick={() => deleteAllSuccess()}>
						{t('columns.delete')} {successReferences.length}
					</Button>
					<Table
						virtual
						key="table-success"
						scroll={{ y: 400 }}
						pagination={false}
						className="ant-table-small"
						columns={columnSuccess}
						dataSource={successReferences}
						style={{ padding: 10 }}
					/>
				</Col>
				<Col
					key="table-errors"
					xs={{ flex: '100%' }}
					sm={{ flex: '100%' }}
					md={{ flex: '50%' }}
					lg={{ flex: '50%' }}
					xl={{ flex: '50%' }}
					style={{ border: '2px solid #f5222d' }}
				>
					<Button type="primary" danger onClick={() => deleteAllErrors()}>
						{t('columns.delete')} {errorReferences.length}
					</Button>
					<Table
						virtual
						scroll={{ y: 400 }}
						pagination={false}
						className="ant-table-small"
						columns={columnError}
						dataSource={errorReferences}
						style={{ padding: 10 }}
					/>
				</Col>
			</Row>
		</MainLayout>
	);
}
