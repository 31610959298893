import React from 'react';
import { Tag, Timeline } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
export function OrderStatusTracking({ detailStatus }) {
	const { t } = useTranslation();

	const items = detailStatus.map((event, index) => ({
		label: event.dateTime,
		key: index,
		children: (
			<div>
				{event.fleetName}-{event.employeeName}:
				<Tag color="default">
					{event.orderStatus === event.orderStatusParent ? (
						<>{event.orderStatusParent}</>
					) : (
						<>
							{event.orderStatusParent}-{event.orderStatus}
						</>
					)}
				</Tag>
			</div>
		),
	}));

	return <Timeline mode="left" items={items} />;
}
