import React from 'react';
import { Timeline } from 'antd';

export function DeliveryTimeline({ detailStatus }) {
	const items = detailStatus.map((detailStatus) => {
		return {
			label: detailStatus.dateTime,
			color: ['FLD', 'LST'].includes(detailStatus.code)
				? 'red'
				: detailStatus.code === 'DLV'
				? 'green'
				: 'blue',
			children: (
				<>
					{detailStatus.name}: {detailStatus.description}
				</>
			),
		};
	});
	return <Timeline mode="left" items={items}></Timeline>;
}
