import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { Option } from 'antd/es/mentions';
import { API_URL_FLEETS_STATES_SELECT, get } from '../../App/Api';
import { useTranslation } from 'react-i18next';

export function FleetStateFilter({
	mode = 'multiple',
	setFleetStates,
	fleetStates = [],
}) {
	const { t, i18n } = useTranslation();

	const [options, setOptions] = useState({});

	useEffect(() => {
		fetchFleetStates();
	}, [i18n.language]);

	const fetchFleetStates = async () => {
		try {
			const response = await get(API_URL_FLEETS_STATES_SELECT);
			let newOptions = [];

			if (response?.data?.length) {
				newOptions = response.data.map((state) => {
					return {
						value: state.stateName,
						label: state.stateName,
					};
				});
			}
			setOptions(newOptions);
		} catch (error) {
			console.error('Error fetching clients:', error);
		}
	};

	const handleOptionsChange = (value) => {
		setFleetStates(value);
	};

	return (
		<Select
			showSearch
			mode={mode}
			optionFilterProp="label"
			onChange={handleOptionsChange}
			defaultValue={fleetStates}
			style={{ width: '100%', margin: '10px 0' }}
			placeholder={t('filters.states')}
			options={options}
		></Select>
	);
}
