import React, { useEffect, useState } from 'react';
import { API_URL_EMPLOYEES, get } from '../../App/Api';
import { Table, Spin, Alert, Button, message, Form, Space, Select } from 'antd';
import { EmployeeForm } from './EmployeeForm';
import { AccordeonFilters } from './Accordeon/AccordeonFilters';
import { useTranslation } from 'react-i18next';
import { Paginator } from '../../Paginator';
import MainLayout from '../../Layout/MainLayout';

export function Employees() {
	const { t, i18n } = useTranslation();

	const [employees, setEmployees] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [isEditing, setIsEditing] = useState(false);
	const [perPage, setPerPage] = useState(10);
	const [employeePage, setEmployeePage] = useState(1);
	const [employeeIds, setEmployeeIds] = useState([]);
	const [fleetIds, setFleetIds] = useState([]);
	const [hasMorePages, setHasMorePages] = useState(true);

	useEffect(() => {
		fetchData();
	}, [perPage, employeePage, i18n.language]);

	const fetchData = async () => {
		setLoading(true);
		try {
			const response = await get(API_URL_EMPLOYEES, {
				employeeIds,
				fleetIds,
				perPage: perPage,
				page: employeePage,
			});
			setEmployees(response.data);

			if (response.data?.length < perPage) {
				setHasMorePages(false);
			} else {
				setHasMorePages(true);
			}
		} catch (error) {
			setError(error.message);
		} finally {
			setLoading(false);
		}
	};

	const handleAddEmployee = () => {
		setEmployees(null);
		setIsEditing(true);
	};

	const handleEdit = (employee) => {
		setIsEditing(true);
		setEmployees(employee);
	};

	const closeEditForm = () => {
		setError();
		setIsEditing(null);
		fetchData();
	};

	const loadMoreEmployee = () => {
		if (hasMorePages && !loading) {
			setLoading(true);
			setEmployeePage((prevPage) => prevPage + 1);
		}
	};

	const handlePreviousPage = () => {
		if (employeePage > 1 && !loading) {
			setLoading(true);
			setEmployeePage((prevPage) => prevPage - 1);
		}
	};

	const handleSearch = () => {
		setEmployeePage(1);
		fetchData();
	};

	const columns = [
		{
			title: t('employee.employeeId'),
			dataIndex: 'employeeId',
			key: 'employeeId',
		},
		{
			title: t('employee.name'),
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: t('fleets.name'),
			dataIndex: ['fleet', 'name'],
			key: 'fleet.name',
		},
		{
			title: t('actions'),
			key: 'edit',
			render: (employee, record) => (
				<Space>
					<Button onClick={() => handleEdit(record, employee)}>
						{t('edit')}
					</Button>
				</Space>
			),
		},
	];

	const filterProps = {
		setFleetIds,
		setEmployeeIds,
		handleSearch: handleSearch,
		setError,
	};

	return (
		<MainLayout>
			{!isEditing && <AccordeonFilters filterProps={filterProps} />}
			{error && (
				<Alert message="Error" description={error} type="error" showIcon />
			)}

			{!isEditing && (
				<>
					{loading ? (
						<Spin size="large" />
					) : error ? (
						<Alert message="Error" description={error} type="error" showIcon />
					) : (
						<>
							<div className="employee-footer">
								<Button type="primary" onClick={handleAddEmployee}>
									{t('employee.add')}
								</Button>
								<Button
									onClick={handlePreviousPage}
									disabled={employeePage === 1}
								>
									{t('previousPage')}
								</Button>
								<Button
									onClick={loadMoreEmployee}
									disabled={loading}
									className="load-more-button"
								>
									{t('nextPage')}
								</Button>
								<Paginator perPage={perPage} setPerPage={setPerPage} />
							</div>
							<div className="employee-table">
								<Table
									dataSource={employees}
									columns={columns}
									rowKey="employeeId"
									pagination={false}
								/>
							</div>
						</>
					)}
				</>
			)}

			{isEditing && (
				<EmployeeForm
					employeeId={employees}
					onClose={closeEditForm}
					setError={setError}
				/>
			)}
		</MainLayout>
	);
}
