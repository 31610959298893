import React from 'react';
import { InputOrderSearch } from '../../SearchReference';
import { OrderStatusCodeFilter } from '../../OrderStatusCodesFilter';
import { Button, Col, Collapse, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { ClientFilter } from '../../../../Client/Filter';
import { EmployeeFilter } from '../../../../Employee/Filter';
import { BusinessFilter } from '../../../../Business/Filter';
const { Panel } = Collapse;

export function AccordionFilters({ filterProps }) {
	const { t } = useTranslation();

	return (
		<Collapse
			accordion
			style={{
				backgroundColor: '#fff',
				borderRadius: '8px',
				marginBottom: '1rem',
				width: '100%',
				margin: '0 auto',
			}}
		>
			<Panel header={t('orders.accordion.Toggle-Filters')} key="mainAccordion">
				<Row gutter={[16, 16]}>
					<Col span={8}>
						<p style={{ fontSize: '11px', margin: '5px 0' }}>
							{t('orders.accordion.Reference')}
						</p>

						<InputOrderSearch {...filterProps} />
					</Col>
					<Col span={8}>
						<p style={{ fontSize: '11px', margin: '5px 0' }}>
							{t('orders.accordion.Bussines')}
						</p>

						<BusinessFilter {...filterProps} />
					</Col>
					<Col span={8}>
						<p style={{ fontSize: '13px', margin: '5px 0' }}>
							{t('filters.clients')}
						</p>
						<ClientFilter {...filterProps} />
					</Col>
					<Col span={8}>
						<p style={{ fontSize: '11px', margin: '5px 0' }}>
							{t('orders.accordion.Employees')}
						</p>
						<div>
							<EmployeeFilter {...filterProps} />
						</div>
					</Col>
					<Col span={8}>
						<p style={{ fontSize: '11px', margin: '5px 0' }}>
							{t('orders.accordion.Orders-Status-Codes')}
						</p>
						<div>
							<OrderStatusCodeFilter {...filterProps} />
						</div>
					</Col>
				</Row>
				<Row justify="end">
					<Col>
						<Button type="primary" onClick={filterProps.handleSearch}>
							{t('orders.accordion.Search')}
						</Button>
					</Col>
				</Row>
			</Panel>
		</Collapse>
	);
}
