import React, { useEffect, useState } from 'react';
import {
	saveToLocalStorage,
	getFromLocalStorage,
	saveTokenToLocalStorage,
} from './storage';
import { useNavigate } from 'react-router-dom';
import { API_URL_LOGIN, post } from '../../App/Api';
import { LenguajeSelect } from '../../navAcordion';
import { useTranslation } from 'react-i18next';

import { useUser } from '../../Context/User';
import { Button, Card, Checkbox, Form, Input, Col, Row } from 'antd';
import Title from 'antd/es/typography/Title';

export function Login() {
	const { login } = useUser();
	const { t } = useTranslation();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [validacionMensaje, setValidacionMensaje] = useState(false);
	const [rememberMe, setRememberMe] = useState(false);
	const navigate = useNavigate();
	const [form] = Form.useForm();

	useEffect(() => {
		form.setFieldsValue({
			email: getFromLocalStorage('login.email'),
			password: getFromLocalStorage('login.password'),
			remember: getFromLocalStorage('login.remember'),
		});
	}, []);

	const handleTrackingClick = () => {
		localStorage.setItem('trackingActivated', 'true');
		navigate('/tracking');
	};

	const handleLogin = async (form) => {
		try {
			setValidacionMensaje();
			if (form.remember) {
				saveToLocalStorage('login.email', form.email);
				saveToLocalStorage('login.password', form.password);
				saveToLocalStorage('login.remember', form.remember);
			}
			delete form.remember;
			const response = await post(API_URL_LOGIN, form);

			if (response?.data) {
				const user = response?.data;
				login(user);
				navigate('/');
				saveTokenToLocalStorage(
					user.access_token,
					user.access_token.expired,
					user.roleId
				);
			} else {
				setValidacionMensaje(t('login.invalidCredentials'));
			}
		} catch (error) {
			setValidacionMensaje(error?.response?.data?.message);
		}
	};

	return (
		<Row justify="center" align="middle" style={{ height: '100vh' }}>
			<Col
				xs={{ flex: '100%' }}
				sm={{ flex: '90%' }}
				md={{ flex: '80%' }}
				lg={{ flex: '60%' }}
				xl={{ flex: '50%' }}
			>
				<Card>
					<Row justify="end" align="middle">
						<Col>
							<Button type="primary" onClick={handleTrackingClick}>
								{t('login.trackingButton')}
							</Button>
							<LenguajeSelect />
						</Col>
					</Row>

					<Title style={{ textAlign: 'center' }}>{t('login.title')}</Title>
					<Form
						form={form}
						labelCol={{
							span: 8,
						}}
						wrapperCol={{
							span: 16,
						}}
						onFinish={handleLogin}
						autoComplete="off"
					>
						<Form.Item
							label={t('login.emailLabel')}
							name="email"
							rules={[
								{
									required: true,
									message: 'Please input your username!',
								},
							]}
						>
							<Input />
						</Form.Item>

						<Form.Item
							label={t('login.passwordLabel')}
							name="password"
							rules={[
								{
									required: true,
									message: 'Please input your password!',
								},
							]}
						>
							<Input.Password />
						</Form.Item>

						<Form.Item
							name="remember"
							valuePropName="checked"
							label={t('login.rememberMeLabel')}
						>
							<Checkbox></Checkbox>
						</Form.Item>

						<Form.Item style={{ textAlign: 'center' }} wrapperCol={24}>
							<Button type="primary" htmlType="submit">
								{t('login.loginButton')}
							</Button>
						</Form.Item>
					</Form>
					{validacionMensaje && (
						<p className="contact__validation">{validacionMensaje}</p>
					)}
				</Card>
			</Col>
		</Row>
	);
}
